.navBar {
    position: absolute;
    /* z-index: 2; */
}

.navBarCorner {
    background: #77037b;
    width: 300px;
    height: 300px;
    position: relative;
    z-index: 0;
}

.navBarCorner::after {
    content: "";
    background: #ffffff;
    position: absolute;
    height: 150px;
    width: 150px;
    bottom: 0;
    right: 0;
    border-radius: 100% 0 0 0;
    z-index: 0;
}

.navLogo {
    position: absolute;
    top: 5px;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    width: 280px;
    height: 110px;
}

.navBarTop {
    background: #77037b;
    height: 150px;
    width: 35vw;
    position: relative;
    z-index: 2;
}

.navBarTop::after {
    content: "";
    background: #77037b;
    position: absolute;
    height: 150px;
    width: 150px;
    top: 0;
    right: -150px;
    border-radius: 0 50% 50% 0;
}

.navWelshFlag {
    padding-left: 160px;
    padding-top: 5px;
    text-align: right;
}

.welshFlagPic {
    height: 180px;
    width: 200px;
}

.navBarSide {
    background: #77037b;
    height: 40vh;
    width: 150px;
    position: relative;
    z-index: 2;
}

.navBarSide::after {
    content: "";
    background-color: #77037b;
    position: absolute;
    height: 150px;
    width: 150px;
    left: 0;
    bottom: -150px;
    border-radius: 0 0 50% 50%;
}

.topButtons {
    position: absolute;
    height: 40px;
    width: 35vw;
    bottom: -20px;
    left: 30px;
    z-index: 2;
}

.sideMenu {
    position: absolute;
    right: -75px;
    top: -50px;
    z-index: 2;
}
