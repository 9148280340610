.customCSS-admin-menu-item {
    text-align: center;
    font-size: 1.5rem;
    background-color: #1e56a0;
    color: white;
    padding: 10px;
}

.customCSS-admin-menu-item:hover {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    background-color: #c3d8e9;
    color: black;
    padding: 10px;
    cursor: pointer;
}

.customCSS-admin-sub-menu-item {
    text-align: center;
    font-size: 1.5rem;
    background-color: #c3d8e9;
    color: black;
    padding: 10px;
}

.customCSS-admin-sub-menu-item:hover {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    background-color: #163172;
    color: white;
    padding: 10px;
    cursor: pointer;
}

.customCSS-admin-menu-item-selected {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    background-color: #c3d8e9;
    color: black;
    padding: 10px;
}

.customCSS-admin-menu-item-sub-selected {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    background-color: #163172;
    color: white;
    padding: 10px;
}

.customCSS-Content-New-Item-Switch {
    vertical-align: middle;
    margin-left: 4px;
}
